import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import BlogNav from "../components/blog/nav/nav"

const Authors = () => {

  const data = useStaticQuery(graphql`
    query {
      allDibPosts {
        edges {
          node {
            author {
              slug
            }
          }
        }
      }
      allDibAuthors {
        edges {
          node {
            name
            slug
            photo
          }
        }
      }
    }
  `)

  const authors = data.allDibAuthors.edges
  const posts = data.allDibPosts.edges

  authors.forEach(author => {
    author.node.numberOfPosts = 0
    posts.forEach(post => {
      if (post.node.author.slug === author.node.slug)
        author.node.numberOfPosts++
    })
  });

  return (
    <Layout>
      <Seo title="Blog" />
      <div className="container mt-4">
        <div className="blog-header">
          <h2>Authors</h2>
          <BlogNav />
        </div>
        <div className="row">
          {authors.map((author, index) => {
            return (
              <div key={`author-${index}`} className="col-md-4">
                <Link className='blog-author' to={"/blog/authors/" + author.node.slug}>
                  {author.node.photo && <img className='blog-author__img' alt='' src={author.node.photo} />}
                  <div className='blog-author__info'>
                    <h3>{author.node.name}</h3>
                    <p>
                      {author.node.numberOfPosts}{" "}
                      {author.node.numberOfPosts > 1 ? "Articles" : "Article"}
                    </p>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Authors
